#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f4e1;
}

.full-height-page {
  padding: 16px;
  min-height: calc(100vh - 16px);
}

.error-page-wrapper {
  padding: 50px 10px 10px 10px;
}

.login-page-paper {
  padding: 30px;
}

.grid-offset-padding-40 {
  padding: 20px;
}

.grid-offset-padding-32 {
  padding: 16px;
}

.grid-offset-padding-24 {
  padding: 12px;
}

.grid-offset-padding-16 {
  padding: 8px;
}

.grid-offset-padding-8 {
  padding: 4px;
}

.link-component {
  text-decoration: none;
}

.dashboard-paper {
  margin-top: 3vh;
  margin-bottom: 3vh;
  margin-right: 3vw;
  margin-left: 3vw;
  padding: 20px;
}

.file-upload-list-fieldName-width {
  max-width: 100px;
}

@media (min-width: 450px) {
  .file-upload-list-fieldName-width {
    max-width: 200px;
  }
}

@media (min-width: 550px) {
  .file-upload-list-fieldName-width {
    max-width: 320px;
  }
}

.casesubmission-form-paper {
  padding: 20px 20px 30px 20px;
}

@media (min-width: 666px) {
  .casesubmission-form-paper {
    width: 570px;
  }
}

.whole-page-drawer {
  min-height: 100%;
}

.drawer-appbar {
  max-width: 70vw;
  word-break: break-word;
}

.builder-main {
  // margin-top: 2vh;
  // margin-bottom: 2vh;
  margin-right: 1.5vw;
  margin-left: 1.5vw;
  padding: 1.5vh 1.5vw;
}

.pr-15px {
  padding-right: 15px;
}
.text-field-width {
  width: 196px;
}

/*
* PRINTING RULES
*/
@media print {
  /* disable paper's box shadow, we don't want it*/
  .order-paper {
    box-shadow: none !important;
  }

  /* add items to hide here*/
  // ::before, // uncomment this if we want to hide input underlines
  .hide-on-print {
    display: none;
  }

  /* hide placeholder texts*/
  ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: transparent !important;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: transparent !important;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: transparent !important;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: transparent !important;
  }
  ::placeholder {
    color: transparent !important;
  }
}

.datepicker-dateonly-timeonly-set-width {
  width: 135px;

  input {
    height: 100%;
  }
}

.datepicker-dateonly-short-width {
  width: 90px;

  input {
    height: 100%;
  }
}

.project-form-hourly-rate-width {
  width: 80px;
}

.button-text-left-icon {
  margin-left: 5px;
}
.mr-3 {
  margin-right: 5px;
}

/* order */
@media (min-width: 477px) {
  .transport-textfield-width {
    width: 300px;
  }
}

.button-text-right {
  margin-right: 10px;
}
