/* handle fixed header, since material's Toolbar changes height depending on screen width */
.app-header {
  height: 56px;
}
.appbar-header {
  height: 56px;
  padding-left: 3vw;
  padding-right: 3vw;
}

@media (min-width: 600px) {
  .app-header {
    height: 64px;
  }
  .appbar-header {
    height: 64px;
  }
}

.header-button-text {
  margin-left: 5px;
}

.header-logo {
  width: 78px;
  height: 52px;
}
