.app-footer {
  margin-top: auto;
}

.appbar-footer {
  height: 40px;
  padding-left: 3vw;
  padding-right: 3vw;
}

#root .toolbar-footer {
  height: 40px;
  min-height: 40px;
}
